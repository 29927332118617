<script setup lang="ts">
	import { MzButton, MzTitle } from '@monizze/monizze-components';
	import { useI18n } from 'vue-i18n';
	import { useRouter } from 'vue-router';
	import { useAuthStore } from '@/stores/auth.ts';
	import { useUserStore } from '@/stores/user.ts';
	import { onBeforeMount } from 'vue';

	const { t } = useI18n();
	const router = useRouter();
	const userStore = useUserStore();

	onBeforeMount(() => {
		userStore.refreshMfa();
	});
</script>

<template>
	<main class="px-4">
		<section class="sm:max-w-[1200px] mx-auto w-full sm:py-20 py-6">
			<div class="card">
				<section>
					<img :src="useAuthStore().isLux ? '/up_luxembourg_logo.svg' : '/monizze_logo.svg'" alt="Logo" />
					<div class="gap-6 mt-10">
						<mz-title :text="t('uzzer.force_mfa.title')" />
						<p class="text-body-intro">{{ t('uzzer.force_mfa.subtitle') }}</p>
					</div>
				</section>
				<section>
					<div class="w-100 flex justify-center mb-10">
						<img :src="`/Mfa/profile.svg`" alt="Security image" />
					</div>
					<mz-title :type="'h2'" :text="t('uzzer.force_mfa.intro.title')" />
					<div class="text-global-text text-info-large mt-6 mb-10">
						<p>{{ t('uzzer.force_mfa.intro.top_text') }}</p>
						<p class="mt-10 mb-2">{{ t('uzzer.force_mfa.intro.question') }}</p>
						<ul style="list-style-type: disc" class="ps-6">
							<template v-for="index in 3" :key="index">
								<li>
									<i18n-t :keypath="`uzzer.force_mfa.intro.reason_${index}`" tag="p">
										<template #bold>
											<b>{{ t(`uzzer.force_mfa.intro.bold_reason_${index}`) }}</b>
										</template>
									</i18n-t>
								</li>
							</template>
						</ul>
						<p class="mt-2">{{ t('uzzer.force_mfa.intro.bottom_text') }}</p>
					</div>

					<mz-button
						:label="t('user.mfa.confirm.card.confirm')"
						:width-auto="true"
						@click.prevent="
							router.replace({ name: JSON.stringify(userStore.mfaMethods).includes('sms') ? 'mandatory-mfa-email' : 'mandatory-mfa-phone' })
						"
					/>
				</section>
			</div>
		</section>
	</main>
</template>

<style lang="sass" scoped>
	.card
		@apply flex md:flex-row flex-col sm:py-12 sm:px-10 py-6 px-4 items-start sm:gap-20 gap-6 rounded-lg border border-global-border bg-neutral-white
		section
			@apply md:w-1/2 w-full
</style>
