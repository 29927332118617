<script setup lang="ts">
	import { MzTitle } from '@monizze/monizze-components';
	import { useI18n } from 'vue-i18n';
	import { useAuthStore } from '@/stores/auth.ts';
	import MzCard from '@/components/MzCard.vue';
	import { useRouter } from 'vue-router';
	import { onMounted } from 'vue';

	const { t } = useI18n();
	const authStore = useAuthStore();
	const router = useRouter();

	onMounted(() => {
		setTimeout(() => {
			router.replace(authStore.targetUrl);
		}, 3000);
	});
</script>

<template>
	<div
		:class="[
			'flex h-screen justify-center items-center sm:justify-end bg-cover bg-no-repeat',
			authStore.isLux ? 'background-lux' : 'login-background',
		]"
	>
		<div class="lg:w-2/5 sm:w-4/6 self-center sm:h-auto sm:my-20">
			<mz-card :is-login-box="true">
				<template #header>
					<div class="w-100 flex justify-center mb-2">
						<img :src="`/Mfa/success.svg`" alt="MFA Success" />
					</div>
					<mz-title :no-margin="true" type="h4" :text="t('uzzer.force_mfa.confirmation.title')" />
				</template>
				<template #content>
					<div class="p-8 flex flex-col gap-12 text-info-large text-center">
						<p>
							{{ t('uzzer.force_mfa.confirmation.description') }}
						</p>
						<i18n-t :keypath="'uzzer.force_mfa.confirmation.redirection'" tag="p">
							<template #platform>
								<b>{{ t(authStore.getPlatformComingFrom()) }}</b>
							</template>
						</i18n-t>
					</div>
				</template>
			</mz-card>
		</div>
	</div>
</template>

<style scoped>
	.login-background {
		background-image: url('/Login/background.jpg');
	}
</style>
