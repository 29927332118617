<script setup lang="ts">
	import { MzButton, MzInput, MzTitle, MzLink } from '@monizze/monizze-components';
	import { useI18n } from 'vue-i18n';
	import { useUserStore } from '@/stores/user.ts';
	import { onBeforeMount, ref, type Ref } from 'vue';
	import { useRoute, useRouter } from 'vue-router';
	import { useAuthStore } from '@/stores/auth.ts';
	import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
	import { useIntervalFn } from '@vueuse/core';

	const { t } = useI18n();
	const userStore = useUserStore();
	const authStore = useAuthStore();
	const validationCode: Ref<string> = ref('');
	const mfaError: Ref<boolean> = ref(false);
	const timeLeft: Ref<number> = ref(15);
	const mfaPayload: Ref<string> = ref('');
	const mfaMethod: Ref<string> = ref('');
	const updatedValue: Ref<string> = ref('');
	const router = useRouter();
	const route = useRoute();

	onBeforeMount(() => {
		if (typeof route.query.payload === 'string' && typeof route.query.method === 'string') {
			mfaPayload.value = route.query.payload;
			mfaMethod.value = route.query.method;
		}
		if (typeof route.query.value === 'string') {
			updatedValue.value = route.query.value;
		}
	});

	const { pause, resume, isActive } = useIntervalFn(() => {
		if (timeLeft.value > 0) timeLeft.value--;
		else {
			pause();
		}
	}, 1000);

	const submitMfa = async () => {
		authStore
			.handleConfirmMfa(mfaPayload.value, validationCode.value)
			.then(async () => {
				validationCode.value = '';
				mfaPayload.value = '';
				userStore.refreshMfa().then(() => {
					userStore.refreshUser();
					router.replace({ name: userStore.mfaMethods.length === 2 ? 'mandatory-mfa-success' : 'mandatory-mfa-email' });
				});
			})
			.catch(() => {
				mfaError.value = true;
			});
	};

	const resendCode = () => {
		authStore.handleLoginMfa(mfaMethod.value, updatedValue.value).then((response) => {
			mfaPayload.value = response.mfaPayload;
		});
		timeLeft.value = 30;
		resume();
	};
</script>

<template>
	<main class="px-4">
		<section class="sm:max-w-[1200px] mx-auto w-full sm:py-20 py-6">
			<div class="card">
				<section>
					<img :src="useAuthStore().isLux ? '/up_luxembourg_logo.svg' : '/monizze_logo.svg'" alt="Logo" class="mb-10" />
					<a href="#" class="text-body-intro self-start hover:opacity-70" @click.prevent="router.back()">
						<FontAwesomeIcon :icon="faArrowLeft" class="pr-2" />
						{{ t('general.back') }}
					</a>
					<mz-title :text="t('user.mfa.confirm.enable')" class="pt-6"></mz-title>
				</section>
				<section>
					<div class="w-100 flex justify-center mb-10">
						<img :src="`/Mfa/${mfaMethod}.svg`" alt="Security image" />
					</div>
					<p class="text-body mb-6">
						{{ t(`user.mfa.confirm.code.${mfaMethod}`) }}
					</p>

					<div class="flex flex-row gap-3 mb-5">
						<p>{{ t('duplicated2fa.page.not_received') }}</p>
						<mz-link
							:label="t(`duplicated2fa.page.resend_${mfaMethod}`)"
							href="#"
							:class="{ 'text-gray-400 cursor-not-allowed': isActive }"
							@click.prevent="!isActive && resendCode()"
						/>
						<p v-if="isActive" class="font-bold">{{ timeLeft }} s</p>
					</div>

					<mz-input
						v-model="validationCode"
						:info="mfaError ? t('user.mfa.confirm.code.error') : ''"
						:validated="mfaError ? false : undefined"
						class="mb-3"
						:label="t('user.mfa.confirm.code.label')"
						:placeholder="t('user.mfa.confirm.code.label')"
						@keydown.enter="submitMfa"
					/>
					<mz-button class="mt-7" :label="t('user.mfa.confirm.card.confirm')" :width-auto="true" @click.prevent="submitMfa" />
				</section>
			</div>
		</section>
	</main>
</template>

<style lang="sass" scoped>
	.card
		@apply flex md:flex-row flex-col sm:py-12 sm:px-10 py-6 px-4 items-start sm:gap-20 gap-6 rounded-lg border border-global-border bg-neutral-white
		section
			@apply md:w-1/2 w-full
</style>
