import {
	createRouter,
	createWebHistory,
	NavigationGuardNext,
	RouteLocationNormalizedGeneric,
	RouteLocationNormalizedLoadedGeneric,
} from 'vue-router';
import { useAuthStore } from '@/stores/auth.ts';
import { client } from 'laravel-precognition-vue';
import Profile from '@/pages/User/Profile.vue';
import Dashboard from '@/pages/User/Dashboard.vue';
import Security from '@/pages/User/Security.vue';
import { useStorage } from '@vueuse/core';
import { useUserStore } from '@/stores/user.ts';
import Access from '@/pages/User/Access.vue';
import MfaValidation from '@/pages/Mfa/MfaValidation.vue';
import PasswordEdit from '@/pages/User/PasswordEdit.vue';
import History from '@/pages/Device/History.vue';
import ForceMfa from '@/pages/Mfa/ForceMfa.vue';
import ForceMfaPhone from '@/pages/Mfa/ForceMfaPhone.vue';
import ForceMfaEmail from '@/pages/Mfa/ForceMfaEmail.vue';
import ForceMfaConfirmation from '@/pages/Mfa/ForceMfaConfirmation.vue';
import ForceMfaSuccess from '@/pages/Mfa/ForceMfaSuccess.vue';

const Login = () => import('@/pages/Login/Login.vue');
const LoginEmail = () => import('@/pages/Login/LoginEmail.vue');
const LoginPhone = () => import('@/pages/Login/LoginPhone.vue');
const Onboarding = () => import('@/pages/Onboarding/Onboarding.vue');
const OnBoardingHome = () => import('@/pages/Onboarding/OnboardingHome.vue');
const OnboardingCard = () => import('@/pages/Onboarding/OnboardingCard.vue');
const OnboardingExistingCard = () => import('@/pages/Onboarding/OnboardingExistingCard.vue');
const OnboardingCode = () => import('@/pages/Onboarding/OnboardingCode.vue');
const OnboardingWelcome = () => import('@/pages/Onboarding/OnboardingWelcome.vue');
const OnboardingTerms = () => import('@/pages/Onboarding/OnboardingTerms.vue');
const OnboardingCreate = () => import('@/pages/Onboarding/OnboardingCreate.vue');
const OnboardingEmailVerification = () => import('@/pages/Onboarding/OnboardingEmailVerification.vue');
const OnboardingPhoneVerification = () => import('@/pages/Onboarding/OnboardingPhoneVerification.vue');
const OnboardingPassword = () => import('@/pages/Onboarding/OnboardingPassword.vue');
const OnboardingConfirmation = () => import('@/pages/Onboarding/OnboardingConfirmation.vue');
const OnboardingMFA = () => import('@/pages/Onboarding/OnboardingMFA.vue');
const Approve = () => import('@/pages/Approve.vue');
const Partner = () => import('@/pages/Partner.vue');
const ForgotPassword = () => import('@/pages/ForgotPassword/ForgotPassword.vue');
const ForgotPasswordEmail = () => import('@/pages/ForgotPassword/ForgotPasswordEmail.vue');
const ForgotPasswordPhone = () => import('@/pages/ForgotPassword/ForgotPasswordPhone.vue');
const ForgotPasswordSent = () => import('@/pages/ForgotPasswordSent.vue');
const ResetPassword = () => import('@/pages/ResetPassword.vue');
const ResetPasswordConfirmed = () => import('@/pages/ResetPasswordConfirmed.vue');
const WeakPassword = () => import('@/pages/WeakPassword.vue');
const ForgotUsername = () => import('@/pages/ForgotUsername.vue');
const ForgotUsernameResponse = () => import('@/pages/ForgotUsernameResponse.vue');
const Duplicated = () => import('@/pages/Duplicated.vue');
const Duplicated2FA = () => import('@/pages/Duplicated2FA.vue');
const FuzzionSuccess = () => import('@/pages/FuzzionSuccess.vue');
const FuzzionError = () => import('@/pages/FuzzionError.vue');
const FuzzionRejection = () => import('@/pages/FuzzionRejection.vue');

const allowNavigationFrom = (allowedRoutes: string[]) => {
	return (_to: RouteLocationNormalizedGeneric, from: RouteLocationNormalizedLoadedGeneric, next: NavigationGuardNext) => {
		if (allowedRoutes.includes(from.name as string)) {
			next();
		} else {
			next({ name: 'onboarding' });
		}
	};
};

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'home',
			redirect: '/user/dashboard',
		},
		{
			path: '/login',
			name: 'login',
			component: Login,
			children: [
				{
					path: 'email',
					name: 'login.email',
					component: LoginEmail,
				},
				{
					path: 'phone',
					name: 'login.phone',
					component: LoginPhone,
				},
			],
		},
		{
			path: '/onboarding',
			component: Onboarding,
			children: [
				{
					path: '',
					name: 'onboarding',
					component: OnBoardingHome,
				},
				{
					path: 'card',
					name: 'onboarding.card',
					component: OnboardingCard,
					beforeEnter: allowNavigationFrom(['onboarding', 'onboarding.welcome']),
				},
				{
					path: 'existingCard',
					name: 'onboarding.existing-card',
					component: OnboardingExistingCard,
				},
				{
					path: 'code',
					name: 'onboarding.code',
					component: OnboardingCode,
					beforeEnter: allowNavigationFrom(['onboarding', 'onboarding.welcome']),
				},
				{
					path: 'welcome',
					name: 'onboarding.welcome',
					component: OnboardingWelcome,
					beforeEnter: allowNavigationFrom(['onboarding.code', 'onboarding.card', 'onboarding.terms-conditions']),
				},
				{
					path: 'terms-conditions',
					name: 'onboarding.terms-conditions',
					component: OnboardingTerms,
					beforeEnter: allowNavigationFrom(['onboarding.welcome', 'onboarding.create']),
				},
				{
					path: 'create',
					name: 'onboarding.create',
					component: OnboardingCreate,
					beforeEnter: allowNavigationFrom(['onboarding.terms-conditions', 'onboarding.email-verification']),
				},
				{
					path: 'confirmation',
					name: 'onboarding.confirmation',
					component: OnboardingConfirmation,
					beforeEnter: allowNavigationFrom(['onboarding.password']),
				},
				{
					path: 'password',
					name: 'onboarding.password',
					component: OnboardingPassword,
					beforeEnter: allowNavigationFrom(['onboarding-mfa', 'onboarding.phone-verification']),
				},
				{
					path: 'phone-verification',
					name: 'onboarding.phone-verification',
					component: OnboardingPhoneVerification,
					beforeEnter: allowNavigationFrom(['onboarding-mfa', 'onboarding.email-verification']),
				},
				{
					path: 'email-verification',
					name: 'onboarding.email-verification',
					component: OnboardingEmailVerification,
					beforeEnter: allowNavigationFrom(['onboarding.create', 'onboarding.phone-verification', 'onboarding-mfa']),
				},
			],
		},
		{
			path: '/forgot-password',
			name: 'forgot-password',
			component: ForgotPassword,
			children: [
				{
					path: 'email',
					name: 'forgot-password.email',
					component: ForgotPasswordEmail,
				},
				{
					path: 'phone',
					name: 'forgot-password.phone',
					component: ForgotPasswordPhone,
				},
			],
		},
		{
			path: '/onboarding-mfa',
			name: 'onboarding-mfa',
			component: OnboardingMFA,
			beforeEnter: allowNavigationFrom(['onboarding.email-verification', 'onboarding.phone-verification']),
		},
		{
			path: '/forgot-password-sent',
			name: 'forgot-password-sent',
			component: ForgotPasswordSent,
		},
		{
			path: '/reset-password',
			name: 'reset-password',
			component: ResetPassword,
		},
		{
			path: '/reset-password-confirmed',
			name: 'reset-password-confirmed',
			component: ResetPasswordConfirmed,
		},
		{
			path: '/weak-password',
			name: 'weak-password',
			component: WeakPassword,
		},
		{
			path: '/forgot-username',
			name: 'forgot-username',
			component: ForgotUsername,
		},
		{
			path: '/forgot-username-response',
			name: 'forgot-username-response',
			component: ForgotUsernameResponse,
		},
		{
			path: '/partner',
			name: 'partner',
			component: Partner,
			props: true,
		},
		{
			path: '/approve',
			name: 'approve',
			component: Approve,
		},
		{
			path: '/duplicated',
			name: 'duplicated',
			component: Duplicated,
		},
		{
			path: '/duplicated-2fa',
			name: 'duplicated-2fa',
			component: Duplicated2FA,
		},
		{
			path: '/fuzzion-success',
			name: 'fuzzion-success',
			component: FuzzionSuccess,
		},
		{
			path: '/fuzzion-error',
			name: 'fuzzion-error',
			component: FuzzionError,
		},
		{
			path: '/fuzzion-rejection',
			name: 'fuzzion-rejection',
			component: FuzzionRejection,
		},
		{
			path: '/user/dashboard',
			name: 'user-dashboard',
			component: Dashboard,
		},
		{
			path: '/user/profile',
			name: 'user-profile',
			component: Profile,
		},
		{
			path: '/user/password',
			name: 'user-password',
			component: PasswordEdit,
		},
		{
			path: '/user/security',
			name: 'user-security',
			component: Security,
		},
		{
			path: '/user/security/history',
			name: 'user-security-history',
			component: History,
		},
		{
			path: '/user/access',
			name: 'user-access',
			component: Access,
		},
		{
			path: '/mfa',
			name: 'mfa',
			component: MfaValidation,
		},
		{
			path: '/mandatory-mfa',
			name: 'mandatory-mfa',
			component: ForceMfa,
		},
		{
			path: '/mandatory-mfa/phone',
			name: 'mandatory-mfa-phone',
			component: ForceMfaPhone,
		},
		{
			path: '/mandatory-mfa/email',
			name: 'mandatory-mfa-email',
			component: ForceMfaEmail,
		},
		{
			path: '/mandatory-mfa/confirmation',
			name: 'mandatory-mfa-confirmation',
			component: ForceMfaConfirmation,
		},
		{
			path: '/mandatory-mfa/success',
			name: 'mandatory-mfa-success',
			component: ForceMfaSuccess,
		},
		{
			path: '/:pathMatch(.*)*',
			name: 'NotFound',
			redirect: { name: 'home' },
		},
	],
});
router.beforeEach(async (to, from) => {
	const authStore = useAuthStore();
	const userStore = useUserStore();

	authStore.token !== '' ? (client.axios().defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token) : null;

	const needGuest: boolean = [
		'login.email',
		'login.phone',
		'forgot-password.email',
		'forgot-password.phone',
		'forgot-password-sent',
		'reset-password',
		'reset-password-confirmed',
		'forgot-username',
		'forgot-username-response',
		'weak-password',
		'onboarding',
		'onboarding.card',
		'onboarding.existing-card',
		'onboarding.code',
		'onboarding.welcome',
		'onboarding.create',
		'onboarding.confirmation',
		'onboarding.password',
		'onboarding.phone-verification',
		'onboarding.email-verification',
		'onboarding.terms-conditions',
		'onboarding-mfa',
	].includes(to?.name?.toString() || '');

	const isUnprotected: boolean = ['mfa'].includes(to?.name?.toString() || '');
	const urlParams = new URLSearchParams(authStore.targetUrl.split('?')[1]);
	const clientId = urlParams.get('client_id');

	if (to?.name?.toString() === 'login') {
		return { name: 'login.email' };
	}

	if (authStore.isLogged) {
		try {
			useStorage('account', '{}').value = JSON.stringify(useUserStore().user);
		} catch (e) {
			console.info('No local storage available: you will need to log again after refreshing the page');
		}
	}

	if (!isUnprotected) {
		if (
			userStore.user.mfa_forced &&
			(!clientId || [import.meta.env.VITE_CLIENT_ID, import.meta.env.VITE_LUX_CLIENT_ID].includes(clientId)) &&
			!from.name?.toString().includes('mandatory-mfa') &&
			!to.name?.toString().includes('mandatory-mfa') &&
			!needGuest
		) {
			return { name: 'mandatory-mfa' };
		}
		if (userStore.user.mfa_forced && from.name?.toString().includes('mandatory-mfa') && !to.name?.toString().includes('mandatory-mfa')) {
			authStore.logout();
			return { name: 'login.email' };
		}
		if (authStore.isLogged && needGuest) {
			return { name: 'home' };
		}

		if (!authStore.isLogged && !authStore.isDuplicatedLogged && !needGuest) {
			authStore.setTargetUrl(to);
			return { name: 'login.email' };
		}
	}
});

export default router;
