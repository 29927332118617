<script setup lang="ts">
	import { MzButton, MzPhone, MzTitle } from '@monizze/monizze-components';
	import { useI18n } from 'vue-i18n';
	import { useRouter } from 'vue-router';
	import { useAuthStore } from '@/stores/auth.ts';
	import { ref, Ref } from 'vue';
	import { useUserStore } from '@/stores/user.ts';

	const { t } = useI18n();
	const router = useRouter();
	const authStore = useAuthStore();
	const {
		user: { phone: storedPhone },
	} = useUserStore();
	const phoneError: Ref<boolean> = ref(false);
	const errorMsg: Ref<string> = ref('');
	const phone: Ref<string> = ref(storedPhone ?? '');

	const handlePhone = () => {
		const value = phone.value !== storedPhone ? phone.value : '';
		authStore
			.handleLoginMfa('sms', value)
			.then((response) => {
				router.push({ name: 'mandatory-mfa-confirmation', query: { payload: response.mfaPayload, method: 'sms', value } });
			})
			.catch((error) => {
				phoneError.value = true;
				errorMsg.value = t(error.errorMsg);
			});
	};
</script>

<template>
	<main class="px-4">
		<section class="sm:max-w-[1200px] mx-auto w-full sm:py-20 py-6">
			<div class="card">
				<section>
					<img :src="authStore.isLux ? '/up_luxembourg_logo.svg' : '/monizze_logo.svg'" alt="Logo" />
					<div class="gap-6 mt-10">
						<mz-title :text="t('uzzer.force_mfa.title')" />
						<p class="text-body-intro">{{ t('uzzer.force_mfa.subtitle') }}</p>
					</div>
				</section>
				<section>
					<div class="w-100 flex justify-center mb-10">
						<img :src="`/Mfa/sms.svg`" alt="Security image" />
					</div>
					<mz-title :type="'h2'" :text="t('user.mfa.confirm.enable')" />
					<p class="text-global-text text-info-large my-6">{{ t('uzzer.force_mfa.phone.description') }}</p>
					<div>
						<mz-phone
							v-model="phone"
							:country="authStore.isLux ? 'LU' : 'BE'"
							:label="t('forgot_password.form.phone')"
							:validated="phoneError ? false : undefined"
							:info="phoneError ? t('user.profile.contact.invalid.phone') : ''"
							@keyup.enter="handlePhone"
						/>
						<mz-button class="mt-10" :label="t('user.mfa.setup.send')" :width-auto="true" :disabled="!phone" @click.prevent="handlePhone" />
					</div>
				</section>
			</div>
		</section>
	</main>
</template>

<style lang="sass" scoped>
	.card
		@apply flex md:flex-row flex-col sm:py-12 sm:px-10 py-6 px-4 items-start sm:gap-20 gap-6 rounded-lg border border-global-border bg-neutral-white
		section
			@apply md:w-1/2 w-full
</style>
