<script setup lang="ts">
	import { MzButton, MzInput, MzTitle } from '@monizze/monizze-components';
	import { useI18n } from 'vue-i18n';
	import { useRouter } from 'vue-router';
	import { useAuthStore } from '@/stores/auth.ts';
	import { ref, Ref } from 'vue';
	import { useUserStore } from '@/stores/user.ts';

	const { t } = useI18n();
	const router = useRouter();
	const authStore = useAuthStore();
	const {
		user: { email: storedEmail },
	} = useUserStore();
	const emailError: Ref<boolean> = ref(false);
	const errorMsg: Ref<string> = ref('');
	const email: Ref<string> = ref(storedEmail);

	const handleEmail = () => {
		const value = email.value !== storedEmail ? email.value : undefined;
		authStore
			.handleLoginMfa('email', value)
			.then((response) => {
				router.push({ name: 'mandatory-mfa-confirmation', query: { payload: response.mfaPayload, method: 'email', value } });
			})
			.catch((error) => {
				emailError.value = true;
				errorMsg.value = t(error.errorMsg);
			});
	};
</script>

<template>
	<main class="px-4">
		<section class="sm:max-w-[1200px] mx-auto w-full sm:py-20 py-6">
			<div class="card">
				<section>
					<img :src="authStore.isLux ? '/up_luxembourg_logo.svg' : '/monizze_logo.svg'" alt="Logo" />
					<div class="gap-6 mt-10">
						<mz-title :text="t('uzzer.force_mfa.title')" />
						<p class="text-body-intro">{{ t('uzzer.force_mfa.subtitle') }}</p>
					</div>
				</section>
				<section>
					<div class="w-100 flex justify-center mb-10">
						<img :src="`/Mfa/email.svg`" alt="Security image" />
					</div>
					<mz-title :type="'h2'" :text="t('user.mfa.confirm.enable')" />
					<p class="text-global-text text-info-large my-6">{{ t('uzzer.force_mfa.email.description') }}</p>
					<div>
						<mz-input
							id="email"
							v-model="email"
							type="email"
							:tab-index="1"
							:label="t('login.form.email')"
							:validated="emailError ? false : undefined"
							required
							@keyup.enter="handleEmail"
						/>
						<mz-button class="mt-10" :label="t('user.mfa.setup.send')" :width-auto="true" :disabled="!email" @click.prevent="handleEmail" />
					</div>
				</section>
			</div>
		</section>
	</main>
</template>

<style lang="sass" scoped>
	.card
		@apply flex md:flex-row flex-col sm:py-12 sm:px-10 py-6 px-4 items-start sm:gap-20 gap-6 rounded-lg border border-global-border bg-neutral-white
		section
			@apply md:w-1/2 w-full
</style>
